import ApplicationConstants from './ApplicationConstants';

document.addEventListener('DOMContentLoaded', function(event){
  
  let numberOfLicens = document.getElementById('number-of-liz');
  if(numberOfLicens !== null){
    
    let licenseCount = numberOfLicens.options[numberOfLicens.selectedIndex].value;
    
    let priceLabel = document.getElementById('price');
    if(priceLabel !== null){
      priceLabel.innerHTML = getPriceLabel(licenseCount);
    }
    
    numberOfLicens.addEventListener('change', function(event){
      let licenseCount = numberOfLicens.options[numberOfLicens.selectedIndex].value;
      
      let price = '';
      price = getPriceLabel(licenseCount);
      
      let priceLabel = document.getElementById('price');
      if(priceLabel !== null){
        priceLabel.innerHTML = price;
      }
    });
  }
  

  
  /*****************/
  /** Assessments **/
  /*****************/
  
  //dis- or enable licence button
  let assessmentCheckboxes = document.getElementsByClassName('assessment-check');
  for(let i = 0; i < assessmentCheckboxes.length; i++){
    assessmentCheckboxes[i].onchange = function(event){
      document.getElementById('delete-license-button').disabled = !checkCheckboxesPresent(assessmentCheckboxes);
    };
  }
  
  //send license deletion request
  let licenseDeletion = document.getElementById('send-delete-license-request');
  if (licenseDeletion !== null) {
    licenseDeletion.onclick = function(event){
      
      let licenseIds = checkedCheckboxes(function(checkBox){
        return checkBox.value.substring(checkBox.value.lastIndexOf('-') + 1);
      });

      let deletionRequests = [];
      for(let i = 0; i < licenseIds.length; i++ ){
        let url = window.location.origin + '/licenses/' + licenseIds[i];
        deletionRequests.push( axios.delete(url));
      }
      axios.all(deletionRequests)
      .then(function(arr){
          location.reload();
      }).catch(function(error){
        location.reload();
      });
    };
  }
  
  
  /*****************/
  /** Licenses *****/
  /*****************/
  
  let numberOfClientLicensesOption = document.getElementById('number-of-licenses');
  if (numberOfClientLicensesOption !== null) {
    let priceInput = document.getElementById('price');
    let price = "";
    for (let i = 0; i < numberOfClientLicensesOption.length; i++) {
      if(numberOfClientLicensesOption[i].selected === true) {
        price = getPrice(numberOfClientLicensesOption[i].value);
        break;
      }
    }
    if(priceInput !== null) {
      priceInput.value = price;
    }
    
    numberOfClientLicensesOption.onchange = function(event){
      let options = event.target;
      
      for (let i = 0; i < options.length; i++) {
        if(options[i].selected === true) {
          price = getPrice(options[i].value);
          break;
        }
      }
      if(priceInput !== null) {
        priceInput.value = price;
      }
    }
  }
  
  /*****************/
  /** Users ********/
  /*****************/
  
  let userCheckboxes = document.getElementsByClassName('user-check');
  for(let i=0; i<userCheckboxes.length; i++){
    userCheckboxes[i].onchange = function(event){
      document.getElementById('delete-clients-button').disabled = !checkCheckboxesPresent(userCheckboxes);
      document.getElementById('delete-coaches-button').disabled = !checkCheckboxesPresent(userCheckboxes);
      document.getElementById('delete-administrators-button').disabled = !checkCheckboxesPresent(userCheckboxes);
    }
  }
  
  $('a[data-toggle="tab"]').on('hide.bs.tab', function (event) {
    for(let i=0; i<userCheckboxes.length; i++){
      userCheckboxes[i].checked = false;
      document.getElementById('delete-clients-button').disabled = true;
      document.getElementById('delete-coaches-button').disabled = true;
      document.getElementById('delete-administrators-button').disabled = true;
    }
  });
  
  $('a[data-toggle="tab"]').on('show.bs.tab', function (event) {
    setButtonVisible(event.target);
    setNavTabBorderBottom(event.target);
  });
  
  let activeTab = document.querySelector('a[data-toggle="tab"].nav-link.active');
  if (activeTab !== null) {
    setButtonVisible(activeTab);
    setNavTabBorderBottom(activeTab);
  }
 
  
  //send users deletion request
  let userDeletionButton = document.getElementById('send-delete-users-request');
  if (userDeletionButton !== null) {
    userDeletionButton.onclick = function(event){
      
      let userIds = checkedCheckboxes(function(checkBox){
        return checkBox.value.substring(checkBox.value.lastIndexOf('-') + 1);
      });
  
      let deletionRequests = [];
      for(let i = 0; i < userIds.length; i++ ){
        let url = window.location.origin + '/users/' + userIds[i];
        deletionRequests.push( axios.delete(url));
      }
      axios.all(deletionRequests)
      .then(function(arr){
        location.reload();
      }).catch(function(error){
        location.reload();
      });
    };
  }
  
  /*****************/
  /** Orders *******/
  /*****************/
  
  //dis- or enable licence button
  let orderCheckboxes = document.getElementsByClassName('order-check');
  for(let i = 0; i < orderCheckboxes.length; i++){
    orderCheckboxes[i].onchange = function(event){
      document.getElementById('delete-order-button').disabled = !checkCheckboxesPresent(orderCheckboxes);
    };
  }
  
  //send license deletion request
  let orderDeletion = document.getElementById('send-delete-order-request');
  if (orderDeletion !== null) {
    orderDeletion.onclick = function(event){
      
      let orderIds = checkedCheckboxes(function(checkBox){
        return checkBox.value.substring(checkBox.value.lastIndexOf('-') + 1);
      });
      
      let deletionRequests = [];
      for(let i = 0; i < orderIds.length; i++ ){
        let url = window.location.origin + '/orders/' + orderIds[i];
        deletionRequests.push( axios.delete(url));
      }
      axios.all(deletionRequests)
      .then(function(arr){
        location.reload();
      }).catch(function(error){
        location.reload();
      });
      
    };
  }
  
});






function getPriceLabel(licenseCount){
  let price = getPrice(licenseCount);
  let languageCode = document.documentElement.lang
  if(languageCode === 'fr') {
    return 'Prix (hors TVA. '+ (ApplicationConstants.VAT * 100) + '%): ' + price;
  }
  return 'Preis (excl. MwSt. '+ (ApplicationConstants.VAT * 100) + '%): ' + price;
}

function getPrice(licenseCount){
  return ApplicationConstants.prices_new[licenseCount];
}

//check if one checkbox is checked
function checkCheckboxesPresent(checkboxes){
  let isChecked = false;
  for(let i = 0; i < checkboxes.length; i++){
    if(checkboxes[i].checked){
      isChecked = true;
      break;
    }
  }
  
  return isChecked;
}

// returns ether all checked checkboxes or the value of all checked
// checkboxes retrieved by the dataFrunction
export function checkedCheckboxes(dataFunction = null){
  let checkBoxData = [];
  let checkboxes = document.getElementsByClassName('form-check-input');
  for(let i = 0; i < checkboxes.length; i++){
    if(checkboxes[i].checked){
      if(dataFunction === null){
        checkBoxData.push(checkboxes[i]);
      }
      else{
        checkBoxData.push(dataFunction(checkboxes[i]));
      }
    }
  }
  return checkBoxData;
}

// set add user button visible depending on nav tab visibility
function setButtonVisible(activeTab){
  if (activeTab.id === 'clients-tab'){
    document.getElementById('client-list-button').classList.remove("d-none");
    document.getElementById('add-client-button').classList.remove("d-none");
    document.getElementById('add-coach-button').classList.add("d-none");
    document.getElementById('add-administrator-button').classList.add("d-none");
  }
  if (activeTab.id === 'coaches-tab'){
    document.getElementById('add-coach-button').classList.remove("d-none");
    document.getElementById('client-list-button').classList.add("d-none");
    document.getElementById('add-client-button').classList.add("d-none");
    document.getElementById('add-administrator-button').classList.add("d-none");
  }
  if (activeTab.id === 'administrators-tab'){
    document.getElementById('add-administrator-button').classList.remove("d-none");
    document.getElementById('client-list-button').classList.add("d-none");
    document.getElementById('add-coach-button').classList.add("d-none");
    document.getElementById('add-client-button').classList.add("d-none");
  }
}

// set tab border-bottom visibel depending on nav tab visibility
function setNavTabBorderBottom(activeTab){
    let tabs = $('a[data-toggle="tab"]');
    for(let i=0; i<tabs.length; i++){
      let parent = tabs[i].parentElement;
      if (tabs[i].id === activeTab.id){
        parent.classList.add("nav-tab-border-bottom");
      } else {
        parent.classList.remove("nav-tab-border-bottom");
      }
    }
}